import React, { useState } from "react";
import {
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaVoicemail,
} from "react-icons/fa";
import "./Contact.css";
import { Connection_Url } from "../utils/productionvariable";
import axios from "axios";
import { MdLocalActivity } from "react-icons/md";

const Contact = ({ setUnderLine }) => {
  // setUnderLine("contact")

  const [firstName, setfirstName] = useState("");
  const [company, setcompany] = useState("");
  const [email, setemail] = useState("");
  const [topic, settopic] = useState("");
  const [message, setmessage] = useState("");
  const [isTrue, setIsTrue] = useState("");

  // const alert = useAlert();

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    // console.log(name, email, rating, dob)
    const data = {
      fname: firstName,
      company: company,
      sub: topic,
      email,
      message,
    };

    document.getElementById("loading").innerHTML = "Proccessing...";
    setIsTrue(true);

    const url = `${Connection_Url}/api/v1/contact`;
    await axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      })
      .then((res) => {
        alert("Email Send SuccussFully. Thank-you for Join us");
        window.location.reload();
      })
      .catch((error) => {
        document.getElementById("loading").innerHTML = "";
        alert(`Technical Error. ${error}`);
        window.location.reload();
      });
  };

  const submitHandler = async (e) => {
    alert("ok");
    e.preventDefault();
    const data = {
      firstName,
      company,
      email,
      topic,
      message,
    };
    setIsTrue(true);

    await axios
      .post(`${Connection_Url}/create`, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      })
      .then((res) => {
        // console.log(res)
        setemail("");
        alert("Email Send SuccussFully. Thank-you for Join us");
        window.location.reload();
      })
      .catch((error) => {
        setIsTrue(false);

        const checkDupicate = error.response.data.message.search("duplicate");
        // console.log(checkDupicate)
        if (checkDupicate !== -1) {
          alert("This Email is Already Exist. Please Enter new Email");
        }

        alert(`Technical Error. ${error.response.data.message}`);
        window.location.reload();
      });
    console.log(data);
  };
  return (
    <div>
      <div className="contactTop">
        <div className="infoContainer">
          <div className="infoBox1">
            <div>
              <div></div>
              <p>We're here to help.</p>
            </div>

            <h1>Head Office</h1>
            <span>
              <MdLocalActivity />
              <p>
                B-522, RIICO Industrial Area, Bhiwadi, Rajasthan - 301 019 INDIA
              </p>
            </span>
            <span>
              <MdLocalActivity />
              <p>+91 9811 290 445 | +91 8860 609 772</p>
            </span>
            <span>
              <FaVoicemail />
              <p>contact@petorosealcones.com</p>
            </span>
          </div>
          <div className="infoBox2">
            {/* <div className="infoBoxItem1">
                        <h3>Let's talk! </h3>
                        <p>(+91) 9694006066  StationeryClub@gmail.com</p>
                    </div>
                    <div className="infoBoxItem2">
                        <h3>Headoffice</h3>
                        <p>Greater Noida, UP (201306) India</p>
                    </div>
                    <div className="infoBoxItem3">
                        <h3>Social Media Handles</h3>
                        <a href="#"><FaFacebook/> Facebook</a> 
                        <a href="#"><FaTwitter/> Twitter</a>
                        <a href="https://www.instagram.com/invites/contact/?i=1eyki4m3i00vf&utm_content=q05ecy3" rel="noreferrer" target='_blank'><FaInstagram /> Instagram</a>
                    </div> */}
          </div>
        </div>
      </div>

      <div className="formContainer">
        <form action="" onSubmit={formSubmitHandler}>
          <div className="formBox">
            <h2>Send your Query</h2>
            <div>
              <input
                type="text"
                required
                placeholder="Name"
                value={firstName}
                onChange={(e) => setfirstName(e.target.value)}
              />
              <input
                type="text"
                required
                placeholder="Company/Organization"
                value={company}
                onChange={(e) => setcompany(e.target.value)}
              />
            </div>
            <div>
              <input
                type="email"
                required
                placeholder="Email"
                value={email}
                onChange={(e) => setemail(e.target.value)}
              />
              <input
                type="text"
                required
                placeholder="Subject"
                value={topic}
                onChange={(e) => settopic(e.target.value)}
              />
            </div>
            <div className="textAreaBox">
              {/* <label htmlFor="">Message</label> */}
              <textarea
                name=""
                required
                placeholder="Type your Messege"
                id=""
                cols="10"
                rows="5"
                value={message}
                onChange={(e) => setmessage(e.target.value)}
              ></textarea>
            </div>
            <p id="loading"></p>
            <input
              className="submitBtn"
              type="submit"
              name=""
              id=""
              disabled={isTrue}
              placeholder="Send Message"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
