import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import items from "../utils/products.js";
import "./Home.css";
import "./ProductTable.css";
import { Link } from "react-router-dom";
import { topScroll } from "../utils/productionvariable.js";

const ProductTable = () => {
  topScroll();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  useEffect(() => {
    if (searchTerm === "") {
      setSearchResults(items);
    }
  }, [searchTerm]);

  const handleSearch = () => {
    // Convert the search term to lowercase for case-insensitive matching
    const lowerSearchTerm = searchTerm.toLowerCase();

    // Filter products based on the search term
    const results = items.filter((product) => {
      // Convert each field value to lowercase for case-insensitive matching
      return Object.values(product).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(lowerSearchTerm)
      );
    });

    // Update the state with the search results
    setSearchResults(results);
  };

  return (
    <div className="homeContainer">
      <div className="sec1Layer">
        <div className="sec1Heading">
          {/* <h2 className="">Features Products </h2> */}
        </div>
      </div>

      <div className="search-Box">
        {/* <div> */}
        <input
          type="text"
          placeholder="Search products..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button onClick={handleSearch}>Search</button>
        {/* </div> */}
      </div>

      <div className="tableBox">
        <div className="tableBoxMain">
          <div className="tableHeader bg">
            <div className="s-no">S.No</div>
            <div className="tableItemProduct">Product Name</div>
            {/* <div className="tableItemChamical">They Call It</div>
            <div className="tableItem">Chemical Name</div> */}
            <div className="tableItem">CAS No.</div>
            {/* <div className="tableItem">FEMA</div> */}
          </div>
          <br />
          {searchResults &&
            searchResults.map((item, i) => (
              <Link
                to={`/product/${item.link}`}
                className={`tableHeader itemBox  ${i % 2 ? "bg1" : "bg2"}`}
              >
                <div className="s-no1">{i + 1}</div>
                <div className="tableItemProduct1">{item.title} </div>
                {/* <div className="tableItemChamical1">{item.subTitle}</div>
                <div className="tableItem1">{item.chemicalName}</div> */}
                <div className="tableItem1">{item.caseNo}</div>
                {/* <div className="tableItem1">{item.FEMA}</div> */}
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ProductTable;
