import React, { useRef, useState } from "react";
import { IoCloseCircle } from "react-icons/io5";
import ReactPlayer from "react-player";
import "./Modal.css";
import { FaReply } from "react-icons/fa";
import { MdReplayCircleFilled } from "react-icons/md";

const Modal = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="close-btn">
          <button onClick={onClose}>
            <IoCloseCircle size={25} />
          </button>
        </div>

        {/* pdf */}
        <div>
          <iframe
            allowfullscreen="allowfullscreen"
            // scrolling="no"
            class="fp-iframe"
            src="https://heyzine.com/flip-book/e08acd5c76.html"
            style={{ width: "100%", height: "60vh" }}
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Modal;
