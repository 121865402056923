import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "../src/Components/Header.js";
import Footer from "../src/Components/Footer.js";

import AllProductsTable from "./Pages/ProductsTable.js";
import ProductPage from "../src/Pages/ProductPage.js";
import HomePage from "../src/Pages/HomePage.js";
import AboutUs from "../src/Pages/AboutUs.js";
import ContactUs from "../src/Pages/Contact";
import { topScroll } from "./utils/productionvariable.js";

function App() {
  topScroll();

  return (
    <BrowserRouter>
      <Header />
      <div className="downMargin">
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/* <Route path="/products" element={<AllProducts />} /> */}
          <Route path="/product/:id" element={<ProductPage />} />
          <Route path="/products" element={<AllProductsTable />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
        </Routes>
      </div>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
