import React, { useRef, useState } from "react";
import { IoCloseCircle } from "react-icons/io5";
import ReactPlayer from "react-player";
import "./Modal.css";
import { FaReply } from "react-icons/fa";
import { MdReplayCircleFilled } from "react-icons/md";

const Modal = ({ isOpen, onClose }) => {
  const [isLooping, setIsLooping] = useState(false);
  const playerRef = useRef(null);

  // Replay video from the start
  const handleReplay = () => {
    if (playerRef.current) {
      playerRef.current.seekTo(0, "seconds");
      playerRef.current.getInternalPlayer().play(); // Auto play
    }
  };

  const stopCancel = () => {
    onClose();
    playerRef.current.getInternalPlayer().stop(); // Auto play
  };
  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="close-btn">
          <button
            onClick={handleReplay}
            style={{ backgroundColor: "transparent", border: "none" }}
          >
            <MdReplayCircleFilled size={25} />
          </button>

          <button onClick={stopCancel}>
            <IoCloseCircle size={25} />
          </button>
        </div>

        {/* <video className="modal-video" controls>
          <source
            src="https://samde.in/assets/images/video.mp4"
            type="video/mp4"
          />
        </video> */}

        <div>
          <ReactPlayer
            className="modal-video"
            ref={playerRef}
            url={require("../images/petrose/Petorose Alcones Feature Video.mp4")}
            controls
            height={"70vh"}
            width="100%"
            loop={isLooping}
          />

          <br />
          <div className="">
            {/* <label class="switch">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label> */}

            {/* <button onClick={() => setIsLooping(!isLooping)}>
              {isLooping ? "Disable Repeat" : "Enable Repeat"}
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
