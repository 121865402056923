// Add your carousel items here, e.g., { image: '...', title: '...' }
// Add your carousel items here, e.g., { image: '...', title: '...' }

const icon1 =
  "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696659656/petrose%20files/gy4i7lump878c1yzkxdu.png";
const icon2 =
  "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696659657/petrose%20files/sghelk9uh5ljsxfsr014.png";
const icon3 =
  "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696659657/petrose%20files/qerwn1cfznigehdemmvg.png";

const chemicals = [
  {
    id: 36,
    // img: "https://plantsmarket.in/cdn/shop/products/RajnigandhaFragrantWhiteFloweringPolianthesTuberosaFlowerBulbsPackOf04Bulbs_4_1024x1024.jpg?v=1659874708",
    img: require("../images/petrose/Arednitryl.png"),
    title: "Arednitryl",
    link: "arednitryl",
    subTitle: "Cantryl",
    about:
      "It enhances aromatic and herbaceous notes, supporting Rosemary & Lavender. It also adds natural greens to citrus outlines imparting a fresh touch to the composition.",

    chemicalName: "(2,2,3- Trimethylcyclopent-3-en-1-yl) acetonitrile",
    formula: "C10H15N",
    caseNo: "15373-31-6",
    FEMA: "",
    TENACITYHour: "30 DAYS",
    foodGradeUrl: icon1,
    chemicalUrl: require("../images/petrose/ArednitrylChem.png"),
  },
  {
    id: 1,
    img: require("../images/petrose/Creamy.png"),
    title: "Creamy Lactone",
    link: "creamy-lactone",
    subTitle: "Methyl Laitone 10%",
    about:
      "A very powerful and effective spiro lactone, enhancing white flower notes like jasmine and tuberose, and fruity notes like peach and osmanthus. It adds milkiness to sandalwood accords and works well with tonka.",
    chemicalName: "8-Methyl-1-oxaspiro[4.5]decan-2-one",
    formula: "C10H16O2",
    caseNo: "77-93-0 / 94201-19-1",
    FEMA: "",
    TENACITYHour: "165 HOURS",
    foodGradeUrl: "",
    chemicalUrl: require("../images/petrose/CreamyChem.png"),
  },
  {
    id: 2,
    img: require("../images/petrose/Ethyl.png"),
    title: "Ethyl Isocaproate",
    link: "ethyl-isocaproate",
    subTitle: "",
    about: "A fruity aroma with medium strength.",
    chemicalName: "Ethyl 4-methylpentanoate",
    formula: "C8H16O2",
    caseNo: "25415-67-2",
    FEMA: "4343",
    TENACITYHour: "3 HOURS",
    foodGradeUrl: "",
    chemicalUrl: require("../images/petrose/EthylChem.png"),
  },
  {
    id: 3,
    img: require("../images/petrose/Gulabow.png"),
    title: "Gulabow",
    link: "gulabow",
    subTitle: "Anatolyl",
    about:
      "A rose-like tropical green waxy floral note, which is sweet and fruity, great for both flavours and fragrances.",
    chemicalName: "Phenyl ethyl 2-methyl butyrate",
    formula: "C13H18O2",
    caseNo: "24817-51-4",
    FEMA: "3632",
    TENACITYHour: "48 HOURS",
    foodGradeUrl: "",
    chemicalUrl: require("../images/petrose/GulabowChem.png"),
  },
  {
    id: 4,
    img: require("../images/petrose/Hyacinth.png"),
    title: "Hyacinth Core",
    link: "hyacinth-core",
    subTitle: "Hyacinth Body",
    about:
      "Defined by notes of hyacinth florals and sweet pea, Hyacinth Core can be used with fruity, green, and violet leaf notes.",
    chemicalName: "[2-(1-Ethoxyethoxy)ethyl]benzene",
    formula: "C12H18O2",
    caseNo: "2556-10-7",
    FEMA: "",
    TENACITYHour: "18 HOURS",
    foodGradeUrl: "",
    chemicalUrl: require("../images/petrose/HyacinthChem.png"),
  },
  {
    id: 5,
    img: require("../images/petrose/Isocaproic.png"),
    title: "Isocaproic Acid",
    link: "isocaproic-acid",
    subTitle: "Hexonic Acid",
    about:
      "A saturated fatty acid with a pungent odor found naturally in various sources like cheese, milk, and sweat. The odor is sour, penetrating, and unpleasant, similar to rancid butter.",
    chemicalName: "4-Methylpentanoic Acid",
    formula: "C6H12O2",
    caseNo: "646-07-1",
    FEMA: "3463",
    TENACITYHour: "72 HOURS",
    foodGradeUrl: "",
    chemicalUrl: require("../images/petrose/IsocaproicChem.png"),
  },
  {
    id: 6,
    img: require("../images/petrose/Jalclone.png"),
    title: "Jalclone",
    link: "jalclone",
    subTitle: "",
    about:
      "A personal favourite of many perfumers, this watery synthetic is fluid and ozonic, with a marine note that amplifies the creation.",
    chemicalName: "7-propyl 2h,4h-1,5-benzodioxepin-3-one",
    formula: "C12H14O3",
    caseNo: "207228-93-1",
    FEMA: "",
    TENACITYHour: "350 HOURS",
    foodGradeUrl: "",
    chemicalUrl: require("../images/petrose/jalChem.png"),
  },
  {
    id: 7,
    img: require("../images/petrose/Muscomme.png"),
    title: "Muscomme W",
    link: "muscomme-w",
    subTitle: "Edenolide",
    about: "White Musk with powdery, creamy notes and fresh fruity undertones.",
    chemicalName:
      "1-(3,3-dimethylcyclohexyl)ethyl propanedioic acid ethyl ester",
    formula: "C15H26O4",
    caseNo: "478695-70-4",
    FEMA: "",
    TENACITYHour: "50 HOURS",
    foodGradeUrl: "",
    chemicalUrl: require("../images/petrose/MuscommeChem.png"),
  },
  {
    id: 8,
    img: require("../images/petrose/Narangama.png"),
    title: "Narangama",
    link: "narangama",
    subTitle: "Orange Flower Ether",
    about:
      "A complete set of fresh-citrusy notes of Orange, Bergamot, and Grapefruit, underlined with Neroli. Narangama possesses hints of florals subtly.",
    chemicalName: "4-(1-Methoxy-1-methylethyl)-1-methylcyclohexene",
    formula: "C11H20O",
    caseNo: "14576-08-0",
    FEMA: "",
    TENACITYHour: "3 HOURS",
    foodGradeUrl: "",
    chemicalUrl: require("../images/petrose/NarangamaChem.png"),
  },
  {
    id: 9,
    img: require("../images/petrose/Palsantol.png"),
    title: "Palsantol",
    link: "palsantol",
    subTitle: "",
    about: "A very powerful and natural sandalwood note.",
    chemicalName:
      "beta,2,2,3-tetramethyldelta-methylene-3-cyclopentene-1-butanol",
    formula: "C14H24O",
    caseNo: "104864-90-6",
    FEMA: "",
    TENACITYHour: "336 HOURS",
    foodGradeUrl: "",
    chemicalUrl: require("../images/petrose/PalsantolChem.png"),
  },
  {
    id: 10,
    img: require("../images/petrose/Val.png"),
    title: "Val Acetate",
    link: "val-acetate",
    subTitle: "Vetikolacetate",
    about:
      "This intensifies the spicy-green aspects while enhancing the bitterness of grapefruit, underlining Vetiver. The overall odor with Vetiver is beautifully composed with Rhubarb, Grapefruit, and Green Peppers.",
    chemicalName: "1,3-Dimethyl-3-phenylbutylacetate",
    formula: "C14H20O2",
    caseNo: "68083-58-9",
    FEMA: "",
    TENACITYHour: "24 HOURS",
    foodGradeUrl: "",
    chemicalUrl: require("../images/petrose/ValChem.png"),
  },
  {
    id: 11,
    img: require("../images/petrose/Valakon.png"),
    title: "Valakon",
    link: "valakon",
    subTitle: "Vetikon",
    about:
      "With a vetiver-like floral profile, Valakon pulls Vetiver oil to the top notes and enhances the fruitiness of gardenia flowers. It also adds a fresh and woody touch to the composition.",
    chemicalName: "4-Methyl-4-phenylpentan-2-one",
    formula: "C12H16O",
    caseNo: "7403-42-1",
    FEMA: "",
    TENACITYHour: "12 HOURS",
    foodGradeUrl: "",
    chemicalUrl: require("../images/petrose/ValakonChem.png"),
  },
];

const items = [
  {
    id: 1,
    // img: "https://mygardenlife.com/wp-content/uploads/2022/12/2888_13-2.jpg",
    img: require("../images/petrose/Altanol.jpg"),
    title: "Altanol",
    link: "altanol",
    subTitle: "Toscanol",
    about:
      "Altanol is a great substitute for basil oil, tarragon oil, methyl chavical and even safrole. It has a sweet and spicy note of estragole and is anisic in essence, with a liquorice character and a touch of saffron and myrtle.",
    chemicalName: "1-Cyclopropylmethyl-4-methoxybenzene",
    formula: "C11H14O",
    caseNo: "16510-27-3",
    FEMA: "4759",
    TENACITYHour: "48",
    foodGradeUrl: icon3,
    chemicalUrl:
      "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696659951/petrose%20files/xv1bjpucqnwwqa6hadpi.png",
  },
  {
    id: 2,
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Piper_betle_plant.jpg/1200px-Piper_betle_plant.jpg",
    img: require("../images/petrose/Chavibetol 50_.jpg"),
    title: "Chavibetol 50%",
    link: "chavibetol-50",
    subTitle: "Chavibetol 50%",
    about:
      "Chavibetol is the primary component of essential oil from the leaves of the betelplant. It is an aromatic compound with a spicy note.",
    chemicalName: "2-methoxy-5-prop-2-enylphenol",
    formula: "- C10H1202",
    caseNo: " 501-19-9",
    FEMA: "",
    TENACITYHour: "240",
    foodGradeUrl: icon3,
    chemicalUrl: require("../images/petrose/chaviChem.png"),
  },
  {
    id: 3,
    // img: "https://5.imimg.com/data5/SELLER/Default/2023/8/339305591/XX/JQ/LH/54770746/anethole-essential-oil-500x500.jpg",
    img: require("../images/petrose/Anethole.jpg"),
    title: "Anethole",
    link: "anethole",
    subTitle: "Anethole 21/22      ",
    about:
      "Being the chief component of star anise, anise seed oil and sweet fennel, Anethole has a sweet and mildly spicy, floral and fruity,anisic-balsimic odour.",
    chemicalName: "1-methoxy-4-[(1Z)-prop-1-en-1-yl]benzene",
    formula: "C10H12O",
    caseNo: "4180-23-8",
    FEMA: "2086",
    TENACITYHour: ">24",
    foodGradeUrl: "../images/pet-icon1.png",
    chemicalUrl: require("../images/chem diagrams/new/anitholeChem.png"),
  },
  {
    id: 4,
    // img: "https://shop.perfumersapprentice.com/images/product/large/9290.jpg",
    img: require("../images/petrose/Caprifloren.jpg"),
    title: "Caprifloren",
    link: "caprifloren",
    subTitle: "Aprifloren",
    about:
      "With an excellent diffusivity, this green floral, lactonic component with aroma notes of peach and coconut, add more flavour to white      flower compositions.",
    chemicalName: "5-Hexyldihydro-4-methylfuran-2(3H)-one",
    formula: " C11H20O2",
    caseNo: "- 67663-01-08",
    FEMA: "3999",
    TENACITYHour: ">180",
    foodGradeUrl: "../images/pet-icon1.png",
    chemicalUrl: require("../images/chem diagrams/new/CapriflorenChem.png"),
  },

  {
    id: 5,
    // img: "https://www.bigbasket.com/media/uploads/p/xl/40198833_1-fresho-celery-hydroponically-grown.jpg",
    img: require("../images/petrose/Celerytone.jpg"),
    title: "Celerytone",
    link: "celerytone",
    subTitle: "Celery Ketone/Celerone",
    about:
      "Celerytone has a green, herbacious celery flavour that blends well with basil and tarragon.",
    chemicalName: "3-Methyl-5-propyl-2-cyclohexen-1-one",
    formula: " C10H16O",
    caseNo: "3720-16-9",
    FEMA: "3577",
    TENACITYHour: "15",
    foodGradeUrl: "../images/only-gmo.png",
    chemicalUrl: require("../images/chem diagrams/new/CelerytoneChem.png"),
  },
  {
    id: 6,
    // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661770/petrose%20files/products-images/xd9auauosuj61zlivq5t.jpg",
    img: require("../images/petrose/Chavicol.jpg"),
    title: "Chavicol",
    link: "chavicol",
    subTitle: "Chavicol",
    about:
      "A colourless liquid found together with terpenes in betel oil, having a phenolic  medicinal and herbal odour.",
    chemicalName: "4-prop-2-enylphenol",
    formula: " C9H10O",
    caseNo: "501-92-8",
    FEMA: "4075",
    TENACITYHour: "48",
    foodGradeUrl: icon3,
    chemicalUrl: require("../images/chem diagrams/new/ChavicolChem.png"),
  },

  {
    id: 7,
    // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661600/petrose%20files/products-images/jsjitm9td0ivzz3nu9fl.jpg",
    img: require("../images/petrose/Chavicyl Acetate.jpg"),
    title: "Chavicyl Acetate",
    link: "chavicyl-acetate",
    subTitle: "Chavicol Acetate",
    about:
      "Another core component of    betel, chavicyl acetate has a strong and spicy odor profil, similar to chavicol and chavibetol.",
    chemicalName: "4-(prop-2-en-1-yl)phenyl    acetate",
    formula: "C11H12O2",
    caseNo: "61499-22-7",
    FEMA: "",
    TENACITYHour: ">24",
    foodGradeUrl: icon1,
    chemicalUrl: require("../images/chem diagrams/new/ChavicylChem.png"),
  },

  {
    id: 8,
    // img: "https://i.pinimg.com/originals/cc/31/fa/cc31fa7d250539c23c9122c480aed9f8.jpg",
    img: require("../images/petrose/Fadeus.jpg"),
    title: "Fadeus",
    link: "fadeus",
    subTitle: "Diola",
    about:
      "A colorless to clear liquid with concentrated    sweetness of herbal    lavender, and fruity tonalities.",
    chemicalName: "1-methoxyhexane    ",
    formula: "C7H16O",
    caseNo: "4747-07-3",
    FEMA: "4291",
    TENACITYHour: "",
    foodGradeUrl: icon2,
    chemicalUrl:
      "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661378/petrose%20files/chem%20diagrams/ms1ovkdbnl6d1qqx4o7m.png",
  },

  {
    id: 9,
    // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661752/petrose%20files/products-images/whvggrg4moxgsdfk4ub0.jpg",
    img: require("../images/petrose/gardeniamide.jpg"),
    title: "Gardeniamide",
    link: "gardeniamide",
    subTitle: "Gardamide",
    about:
      "With a grapefruit citrus    heartnote and rhubarb,    Gardeniamide works well with other green citrus    notes, adding a long lasting citrus effect.",
    chemicalName: "N,2-Dimethyl-Nphenylbutyramide",
    formula: "C12H17NO",
    caseNo: "84434-18-4",
    FEMA: "3241",
    TENACITYHour: ">72",
    foodGradeUrl: icon3,
    chemicalUrl: require("../images/chem diagrams/new/GardeniamideChem.png"),
  },

  {
    id: 10,
    // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661672/petrose%20files/products-images/q7uptejuymcmrn9oxbjj.jpg",
    img: require("../images/petrose/gracazine.jpg"),
    title: "Grecazine",
    link: "grecazine",
    subTitle: "Verdoracine",
    about:
      "Grecazine is green with fresh carrots and galbanum. It supports earthy and herbaceous notes.",
    chemicalName: "4-isopropyl-1-methyl-2-(prop-1-enyl)benzene",
    formula: "C13H18",
    caseNo: "14374-92-6",
    FEMA: "",
    TENACITYHour: "24",
    foodGradeUrl: icon3,
    chemicalUrl: require("../images/chem diagrams/new/GrecazineChem.png"),
  },

  // {
  //   id: 11,
  //   // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661717/petrose%20files/products-images/h9nfxtn3ha92z0l7m17g.jpg",
  //   img: require("../images/petrose/Guajavate.jpg"),
  //   title: "Guajavate",
  //   link: "guajavate",
  //   subTitle: "Guavanate",
  //   about:
  //     "Guajavate is exotic and woody, with tropical creamy dry-down. It reflects tropical guava notes.",
  //   chemicalName: "Methyl (Z)-5-octenoate",
  //   formula: "C9H16O2",
  //   caseNo: " 41654-15-3",
  //   FEMA: "4165",
  //   TENACITYHour: "",
  //   foodGradeUrl: icon3,
  //   chemicalUrl:
  //     "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661379/petrose%20files/chem%20diagrams/u8peviyukazmvxwvkfrx.jpg",
  // },
  // {
  //   id: 12,
  //   // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661689/petrose%20files/products-images/l80ihd1kdl4hedvombet.jpg",
  //   img: require("../images/petrose/Hazelnut Ketone.jpg"),
  //   title: "Hazelnut Ketone",
  //   link: "hazelnut-ketone",
  //   subTitle: "Filbertone",
  //   about: "A fruity note with hazelnut flavour.",
  //   chemicalName: "5-methylhept-2-en-4-one",
  //   formula: "C8H14O",
  //   caseNo: " 102322-83-8",
  //   FEMA: "3761",
  //   TENACITYHour: "240",
  //   foodGradeUrl: icon3,
  //   chemicalUrl: "../images/Caprifloren.png",
  // },
  {
    id: 13,
    // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661599/petrose%20files/products-images/k5qxhox34wg10xabod0b.jpg",
    img: require("../images/petrose/Hijacynth.jpg"),
    title: "Hijacynth",
    link: "hijacynth",
    subTitle: "Jacinthaflor",
    about:
      "A powerful floral note in thedirection of Jasmine and Hyacinth, animalic as well in character.",
    chemicalName: "2-methyl-4-phenyl-1,3-dioxolane",
    formula: "C10H12O2",
    caseNo: "33941-99-0    ",
    FEMA: "",
    TENACITYHour: "10",
    foodGradeUrl: icon1,
    chemicalUrl: require("../images/chem diagrams/new/HijacynthChem.png"),
  },
  {
    id: 14,
    // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661780/petrose%20files/products-images/o9syjdz32mhjjf57xcpj.jpg",
    img: require("../images/petrose/Hyacinth Acetal.jpg"),
    title: "Hyacinth Acetal",
    link: "hyacinth-acetal",
    subTitle: "Acetal CD",
    about:
      "Common notes of floral    green, honey, rose and tea, the odour of Hyacinth Acetal is dry and leafy, while the flavour is aromatically herbal.",
    chemicalName: "2-(phenylmethyl)-1,3-dioxan-5-ol",
    formula: "C11H14O3",
    caseNo: " 29895-73-6  ",
    FEMA: "2877",
    TENACITYHour: ">48",
    foodGradeUrl: icon3,
    chemicalUrl:
      "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661380/petrose%20files/chem%20diagrams/aa1651ofw1yicrspjplq.png",
  },
  {
    id: 15,
    // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661835/petrose%20files/products-images/q8ojagubeezahzerdzt5.jpg",
    img: require("../images/petrose/Jasnoate.jpg"),
    title: "Jasnoate",
    link: "jasnoate",
    subTitle: "Gelsone",
    about: "Very jasmin-like, with a wine bouquet and fruity undertones.",
    chemicalName: "Ethyl 2-acetyloctanoate",
    formula: "C12H22O3",
    caseNo: " 29214-60-6  ",
    FEMA: "4459",
    TENACITYHour: "48",
    foodGradeUrl: icon2,
    chemicalUrl:
      "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661378/petrose%20files/chem%20diagrams/oscx4g3klpf4zovcz7bh.jpg",
  },
  {
    id: 16,
    // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661629/petrose%20files/products-images/jiqklefzz8jeey70xcms.jpg",
    img: require("../images/petrose/Laval.jpg"),
    title: "Laval",
    link: "laval",
    subTitle: "Lavender Aldehyde",
    about:
      "A powerful, diffusing herbaceous odour, LOVAL has lavender and cocoa powder overtones. It blends well with bergamot and clary sage, imparting dry woody notes for blueberry,cranberry and chocolate.",
    chemicalName: "Isodihydrolavandulal",
    formula: "C10H18O",
    caseNo: "  35158-25-9 ",
    FEMA: "3406",
    TENACITYHour: "8-10",
    foodGradeUrl: icon3,
    chemicalUrl: require("../images/chem diagrams/new/LavalChem.png"),
  },
  {
    id: 17,
    // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661751/petrose%20files/products-images/kv5vqkys3p3nml2e2dpw.jpg",
    img: require("../images/petrose/Megavanil.jpg"),
    title: "Megavanil ",
    link: "megavanil",
    subTitle: "Ultravanil",
    about:
      "Magavanil is a strong vanilla absolute note, that mixes well with vanillin and ethyl vanillin. Unlike other vanilla notes, it doesn't discolor much.",
    chemicalName: "2-ethoxy-4-Methylphenol",
    formula: "C9H12O2",
    caseNo: "  25265-71-8/2563-07-7",
    FEMA: "",
    TENACITYHour: ">350",
    foodGradeUrl: icon3,
    chemicalUrl: require("../images/chem diagrams/new/MegavanilChem.png"),
  },
  // {
  //   id: 18,
  //   // img: "https://agrilifetoday.tamu.edu/wp-content/uploads/2020/06/AdobeStock_166257354_edited.jpg",
  //   img: require("../images/petrose/Melmor.jpg"),
  //   title: "Melmor ",
  //   link: "melmor ",
  //   subTitle: "Calone (Watermelon Ketone)",
  //   about: "A fresh watery, melony and green note.",
  //   chemicalName: "8-methyl-1,5-benzodioxepin-3-one",
  //   formula: "C10H10O3",
  //   caseNo: "  28940-11-6",
  //   FEMA: "",
  //   TENACITYHour: ">600",
  //   foodGradeUrl: icon3,
  //   chemicalUrl:
  //     "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661380/petrose%20files/chem%20diagrams/gd5413uh2x1b7wrylwz5.jpg",
  // },
  {
    id: 19,
    // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661651/petrose%20files/products-images/yfrotdhv7pjdtz4tmhf4.jpg",
    img: require("../images/petrose/Methyl-3-Nonanoate.jpg"),
    title: "Methyl-3-Nonenoate ",
    link: "methyl-3-nonenoate",
    subTitle: "Methyl-3-Nonenoate",
    about:
      "An intense fresh, fruity,    green odor with cucumber    undrtones, with a varying    tropical taste resembling melons and apples.",
    chemicalName: "Methyl-3-nonenoate",
    formula: "C10H18O2",
    caseNo: "  13481-87-3",
    FEMA: " 3710",
    TENACITYHour: ">24",
    foodGradeUrl: icon3,
    chemicalUrl: require("../images/chem diagrams/new/MethylChem.png"),
  },
  {
    id: 20,
    // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661835/petrose%20files/products-images/q8ojagubeezahzerdzt5.jpg",
    img: require("../images/petrose/Neroli Ketone.jpg"),
    title: "Neroli Ketone",
    link: "neroli-ketone",
    subTitle: "Nerone",
    about:
      "A powerfully diffusive note of Neroli together with the dry freshness of the greens.",
    chemicalName: "1-(2-methyl-5-propan-2-yl-1-cyclohex-2-enyl)propan-1-one",
    formula: "C13H22O",
    caseNo: " - 31375-17-4",
    FEMA: "",
    TENACITYHour: ">30",
    foodGradeUrl: icon3,
    chemicalUrl: require("../images/chem diagrams/new/NeroliChem.png"),
  },
  // {
  //   id: 21,
  //   // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661835/petrose%20files/products-images/q8ojagubeezahzerdzt5.jpg",
  //   img: require("../images/petrose/Neroli One.jpg"),
  //   title: "Neroli One",
  //   link: "neroli-one",
  //   subTitle: "Nerolione",
  //   about: "A floral fresh ornage blossom character that is highly diffusive.",
  //   chemicalName: "1-(3-Methyl-benzofuran-2-yl)-ethanone",
  //   formula: "C11H10O",
  //   caseNo: "  23911-56-0",
  //   FEMA: "",
  //   TENACITYHour: ">1 Month",
  //   foodGradeUrl: icon1,
  //   chemicalUrl:
  //     "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661381/petrose%20files/chem%20diagrams/qqcnbnaumrvvx1kpifqs.png",
  // },
  {
    id: 22,
    // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661867/petrose%20files/products-images/z2onfzbitn8dldm5jsd8.jpg",
    img: require("../images/petrose/Newfoleon.jpg"),
    title: "Newfoleon",
    link: "newfoleon",
    subTitle: "Neofolione/ Novafoleon",
    about:
      "NewFoleon is designed for perfect blending with floral notes, imparting the greeny sense of freshness. It is stable, while diffusing comfortably",
    chemicalName: "Methyl-2-nonenoate",
    formula: "C10H18O2",
    caseNo: " 111-79-5 ",
    FEMA: " 2725",
    TENACITYHour: "15",
    foodGradeUrl: icon3,
    chemicalUrl:
      "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661379/petrose%20files/chem%20diagrams/u8peviyukazmvxwvkfrx.jpg",
  },
  {
    id: 23,
    // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661642/petrose%20files/products-images/vyu8zlj9jr5ji9l1lcm5.jpg",
    img: require("../images/petrose/nutclone one.jpg"),
    title: "Nutclone One  ",
    link: "nutclone-one",
    subTitle: "Nutty Cyclohexanone",
    about: "A powerful nutty, caramel-like odor.",
    chemicalName: "3-methyl-2-cyclohexanone",
    formula: "C7H10O",
    caseNo: " C7H10O",
    FEMA: " 3360",
    TENACITYHour: "24",
    foodGradeUrl: icon1,
    chemicalUrl: require("../images/chem diagrams/new/NutcloneChem.png"),
  },
  {
    id: 24,
    // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661629/petrose%20files/products-images/jiqklefzz8jeey70xcms.jpg",
    img: require("../images/petrose/Orrisnitril.jpg"),
    title: "Orrisnitrile",
    link: "orrisnitrile",
    subTitle: "Irisnitrile",
    about:
      "Strengthens orris and violet flower notes, with green    fatty floral tones",
    chemicalName: "2-nonene nitrile",
    formula: "C9H15N",
    caseNo: " 29127-83-1    ",
    FEMA: "",
    TENACITYHour: "48",
    foodGradeUrl: icon1,
    chemicalUrl: require("../images/chem diagrams/new/OrrisnitrilChem.png"),
  },
  {
    id: 25,
    // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661642/petrose%20files/products-images/vyu8zlj9jr5ji9l1lcm5.jpg",
    img: require("../images/petrose/Para Propyl Phenol.jpg"),
    title: "Para Propyl Phenol",
    link: "para-propyl-phenol",
    subTitle: "Para Propyl Phenol",
    about:
      "A medicinal phenolic odor that is undesirably sweet, clove-like and somewhat smoky",
    chemicalName: "4-Propylphenol",
    formula: "C9H12O",
    caseNo: " 645-56-7   ",
    FEMA: "3649",
    TENACITYHour: "10",
    foodGradeUrl: icon3,
    chemicalUrl:
      "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696789454/petrose%20files/chem%20diagrams/gywmuyezkm2hfrdgxrfm.png",
  },
  // {
  //   id: 26,
  //   // img: "https://planteli.com/wp-content/uploads/2021/06/sphagnum-peat-moss.jpg",
  //   img: require("../images/petrose/Petomoss.jpg"),
  //   title: "Petomoss",
  //   link: "petomoss",
  //   subTitle: "Evernyl",
  //   about: "Powdery and soft oakmoss, with dry earthy notes.",
  //   chemicalName: "methyl 2,4-dihydroxy-3,6-dimethylbenzoate",
  //   formula: "C10H12O4",
  //   caseNo: " 4707-47-5  ",
  //   FEMA: "4759",
  //   TENACITYHour: ">72",
  //   foodGradeUrl: icon1,
  //   chemicalUrl:
  //     "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696789452/petrose%20files/chem%20diagrams/accfetsduldkg0wqjk9x.png",
  // },
  {
    id: 27,
    // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661659/petrose%20files/products-images/niuwaeg0z2hkfsuxtydi.jpg",
    img: require("../images/petrose/Prosethyl.jpg"),
    title: "Prosethyl",
    link: "prosethyl",
    subTitle: "Rosethyl",
    about: "Rosy note with green, earthy, spicy and honey    undertones.",
    chemicalName: "1-(Ethoxymethyl)-2-    methoxybenzene",
    formula: "C10H14O2",
    caseNo: " 64988-06-03",
    FEMA: "",
    TENACITYHour: ">48",
    foodGradeUrl: icon1,
    chemicalUrl:
      "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696789452/petrose%20files/chem%20diagrams/k8omv9eeavjuf5owghw2.png",
  },
  // {
  //   id: 28,
  //   // img: "https://hindi.cdn.zeenews.com/hindi/sites/default/files/styles/zm_700x400/public/2023/01/18/1543507-6.jpg?itok=PQ1h5dJy",
  //   img: require("../images/petrose/Phenyl Ethyl Anthranilate.jpg"),
  //   title: "Phenyl Ethyl Anthranilate",
  //   link: "phenyl-ethyl-anthranilate",
  //   subTitle: "Phenyl Ethyl Anthranilate",
  //   about: "Phenethyle Anthranilate is sweetly fruity, floral and honey like.",
  //   chemicalName: "2-Phenylethyl 2-aminobenzoate",
  //   formula: "C15H15NO2",
  //   caseNo: " 133-18-6",
  //   FEMA: "2859",
  //   TENACITYHour: ">24",
  //   foodGradeUrl: icon3,
  //   chemicalUrl:
  //     "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696789452/petrose%20files/chem%20diagrams/dmfciy4giyldnjfkn53e.png",
  // },
  {
    id: 29,
    // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661605/petrose%20files/products-images/b034iqkrcb0fp39muo18.jpg",
    img: require("../images/petrose/Phenyl Ethyl Propionate.jpg"),
    title: "Phenyl Ethyl Propionate",
    link: "phenyl-ethyl-propionate",
    subTitle: "Phenyl Ethyl Propionate",
    about:
      "Like other phenolics, Phenyl Ethyl Propionate ha fruity, honeyed and slightly spicy odour.",
    chemicalName: "Phenyl Ethyl Propionate",
    formula: "C11H14O2",
    caseNo: " 122-70-3",
    FEMA: "2867",
    TENACITYHour: "96",
    foodGradeUrl: icon3,
    chemicalUrl:
      "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696789452/petrose%20files/chem%20diagrams/ykxyov0rt2dht8ovtlxx.png",
  },
  {
    id: 30,
    // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661751/petrose%20files/products-images/kv5vqkys3p3nml2e2dpw.jpg",
    img: require("../images/petrose/Rhumytal.jpg"),
    title: "Rhumytal",
    link: "rhumytal",
    subTitle: "Rhum Acetal",
    about: "Rummy-tobacco like, with fruity notes and woody undertones.",
    chemicalName: "Cyclohexanone Diethyl Acetal",
    formula: "C10H20O2",
    caseNo: "1670-47-9",
    FEMA: "4516",
    TENACITYHour: ">24",
    foodGradeUrl: icon3,
    chemicalUrl: require("../images/chem diagrams/new/RhumytalChem.png"),
  },
  // {
  //   id: 31,
  //   // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661689/petrose%20files/products-images/l80ihd1kdl4hedvombet.jpg",
  //   img: require("../images/petrose/Syamanoot.jpeg"),
  //   title: "Syamanoot",
  //   link: "syamanoot",
  //   subTitle: "Nuezate",
  //   about:
  //     "An aroma characteristic of    black walnut, accompanied by woody nuances and a subtle herbaceousness.",
  //   chemicalName: "Ethyl 3-methyl-2-oxo-pentanoate",
  //   formula: "C8H14O3",
  //   caseNo: "26516-27-8",
  //   FEMA: "4903",
  //   TENACITYHour: ">48",
  //   foodGradeUrl: icon3,
  //   chemicalUrl:
  //     "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696789454/petrose%20files/chem%20diagrams/dxap493icfpuve7q6b9k.png",
  // },
  {
    id: 32,
    // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661689/petrose%20files/products-images/p97dmylurclb7duitr3n.jpg",
    img: require("../images/petrose/Vanilathon.jpg"),
    title: "Vanilathon",
    link: "vanilathon",
    subTitle: "Creosol",
    about:
      "A modifier in floral, leathery and animalic compositions,    Vanilathon is a spicy vanila note with leather like hints and balsamin undertone.",
    chemicalName: "2-methoxy-4-methylphenol",
    formula: " C8H10O2",
    caseNo: "93-51-6",
    FEMA: "2671",
    TENACITYHour: "24",
    foodGradeUrl: icon3,
    chemicalUrl:
      "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696789454/petrose%20files/chem%20diagrams/kvbq0ivsoy50vrcybp63.png",
  },
  // {
  //   id: 33,
  //   // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661867/petrose%20files/products-images/z2onfzbitn8dldm5jsd8.jpg",
  //   img: require("../images/petrose/Vinylgucol.jpg"),
  //   title: "Vinylgucol",
  //   link: "vinylgucol",
  //   subTitle: "4-Vinyl Guaiacol",
  //   about:
  //     "Primarily clove-like, spicy,    smoky powdery aromatic    character, with balsamic ambery flavour.",
  //   chemicalName: "2-methoxy-4-vinyl phenol",
  //   formula: " C9H10O2",
  //   caseNo: "7786-61-0",
  //   FEMA: "2675",
  //   TENACITYHour: "240",
  //   foodGradeUrl: icon2,
  //   chemicalUrl:
  //     "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696789454/petrose%20files/chem%20diagrams/xkkpmsiyzfil9wiih6sm.png",
  // },
  {
    id: 34,
    // img: "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696661939/petrose%20files/products-images/phm3o1ziqojsdq3ns3tt.jpg",
    img: require("../images/petrose/Whiskey 91.jpg"),
    title: "Whiskey 91",
    link: "whiskey-91",
    subTitle: "Whiskey Lactone",
    about:
      "With a tonka type odor of    coumarin, coconut, maple, toasted nuts with a burnt    character, Whiskey 91 is lactonic in flavour, woody, creamy and nutty nuances.",

    chemicalName: "5-butyl-4-methyloxolan-2-one",
    formula: "  C9H16O2",
    caseNo: "39212-23-2",
    FEMA: "3803",
    TENACITYHour: "60",
    foodGradeUrl: icon1,
    chemicalUrl: require("../images/chem diagrams/new/WhiskeyChem.png"),
  },
  {
    id: 35,
    // img: "https://plantsmarket.in/cdn/shop/products/RajnigandhaFragrantWhiteFloweringPolianthesTuberosaFlowerBulbsPackOf04Bulbs_4_1024x1024.jpg?v=1659874708",
    img: require("../images/petrose/White Tuberose.jpg"),
    title: "White Tuberose",
    link: "white-tuberose",
    subTitle: "Methyl Tuberate",
    about:
      "Methyl Tuberate is a    powerful modifier to floral    accords. The characteristic note is that of natural white flower, imparting tuberose and lactonic fragrance.",

    chemicalName: "4-methyl-5-pentyloxolan-2-one",
    formula: "C10H18O2",
    caseNo: "33673-62-0",
    FEMA: "",
    TENACITYHour: "160",
    foodGradeUrl: icon1,
    chemicalUrl:
      "https://res.cloudinary.com/dwevw3pnz/image/upload/v1696789454/petrose%20files/chem%20diagrams/rkjfxp8fgei3hzncev1w.png",
  },
];

export default sortByTitle([...items, ...chemicals]);

function sortByTitle(data) {
  return data.sort((a, b) => a.title.localeCompare(b.title));
}
