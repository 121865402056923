import React, { useState } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import {
  FaDownload,
  FaFile,
  FaFilePdf,
  FaVideo,
  FaWhatsapp,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Header.css";
import ModalWhatsappPage from "./ModalPage";
import ModalVideoPage from "./ModalVideoPage.js";
import ModalPdf from "./ModalPdf.js";
// import { Link } from 'react-router-dom'/

const Header = () => {
  const [isHide, setisHide] = useState(true);
  const [isHideSearch, setisHideSearch] = useState(true);

  const searchHandle = () => {
    let node = document.getElementById("search");
    if (isHideSearch) {
      node.classList.remove("hideSearch");
      node.classList.add("showSearch");
      setisHideSearch(false);
    } else {
      node.classList.remove("showSearch");
      node.classList.add("hideSearch");
      setisHideSearch(true);
    }
  };

  const menuHandler = (e) => {
    if (isHide) {
      document
        .getElementsByClassName("menuContainer")[0]
        .classList.remove("hide");
      document.getElementsByClassName("menuContainer")[0].classList.add("show");
      setisHide(false);
    } else {
      document.getElementsByClassName("menuContainer")[0].classList.add("hide");
      document
        .getElementsByClassName("menuContainer")[0]
        .classList.remove("show");

      setisHide(true);
    }
  };
  // modal------------------------------------------------

  const [isModal, setIsModal] = useState(false);
  const [isModalVideo, setIsModalVideo] = useState(true);
  const [isModalPdf, setIsModalPdf] = useState(false);

  const onClose = () => {
    setIsModalVideo(false);
    setIsModalPdf(false);

    setIsModal(!isModal);
  };
  const onCloseVideo = () => {
    setIsModal(false);
    setIsModalPdf(false);

    setIsModalVideo(!isModalVideo);
  };
  const onClosePdf = () => {
    setIsModal(false);
    setIsModalVideo(false);

    setIsModalPdf(!isModalPdf);
  };
  return (
    <div className="HeaderContainerMain">
      <div className="whatsappFormBtnBox">
        <button
          onClick={onClosePdf}
          className=" modalFormBtn whatsappFormBtn bgWhite"
          title="View Product Catalog"
        >
          <FaFile />
        </button>

        <button
          onClick={onCloseVideo}
          className=" modalFormBtn whatsappFormBtn bgWhite"
          title="Watch Feature Video"
        >
          <FaVideo />
        </button>

        <button onClick={onClose} className="whatsappFormBtn">
          <FaWhatsapp size={28} color="white" />
        </button>
      </div>

      {/* ----------------------- modals------------------- */}
      {isModal && <ModalWhatsappPage isOpen={isModal} onClose={onClose} />}

      {isModalVideo && (
        <ModalVideoPage isOpen={isModalVideo} onClose={onCloseVideo} />
      )}

      {isModalPdf && <ModalPdf isOpen={isModalPdf} onClose={onClosePdf} />}

      <header>
        <div className="HeaderContainer">
          <div className="logoBox">
            <Link to={"/"}>
              <img
                src={require("../images/petroseLogo.jpg")}
                // width="100%"
                alt="logo"
                style={{ mixBlendMode: "darken" }}
              />
              <img
                src={require("../images/TYPEFACEE.png")}
                // width="100%"
                alt="logo"
                // style={{mixBlendMode:'darken'}}
              />
              {/* <h3>Petrose Alcones</h3> */}
            </Link>
          </div>

          <div className="linkBox">
            {/* <Link to={"/"} className="activ e ">
              Home
            </Link> */}
            {/* <a  to={"/products"}>Products</a > */}
            <Link to={"/products"}>Products</Link>
            <Link to={"/about"}>About us</Link>
            <Link to={"contact"}>Contact us</Link>

            {/* <iframe
              allowfullscreen="allowfullscreen"
              scrolling="no"
              class="fp-iframe"
              src="https://heyzine.com/flip-book/e08acd5c76.html"
              style="border: 1px solid lightgray; width: 100%; height: 400px;"
            ></iframe> */}

            {/* <a
              href={"https://heyzine.com/flip-book/e08acd5c76.html"}
              href={require("../images/petrose/ProductCatalogueWeb.pdf")}
              download={"ProductCatalogueWeb2025"}
              target="_blank"
              className="BroBtn"
            >
              Brochure
            </a> */}
            <button className="BroBtn" onClick={onClosePdf}>
              Catalogue
            </button>

            <a
              href={require("../images/petrose/ProductCatalogueWeb.pdf")}
              download={"ProductCatalogueWeb2025"}
              // target="_blank"
              // className="BroBtn"
            >
              {" "}
              <FaDownload />
            </a>
          </div>
          {/* <div className="iconBox">
        
            <a href="https://www.instagram.com/invites/contact/?i=1eyki4m3i00vf&utm_content=q05ecy3" target="_blank">
              <FaInstagram />
            </a>
            <a href="https://youtube.com/@sauravkori217" target="_blank">
              <FaYoutube />
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href={"https://wa.me/72918 54677"}
            >
              <FaWhatsapp />
            </a>
            
          </div> */}
          <div onClick={() => menuHandler()} className="menu">
            <BiMenuAltRight />
          </div>
        </div>

        {/* <div className="hideSearch searchBox " id="search">
            <div>
              <input type="text" placeholder="search tools"  />
              <input type="submit" value={'Find'} />
            </div>
        </div> */}

        <div className="menuContainer hide">
          <div>
            <Link onClick={() => menuHandler()} to={"/products"}>
              Products
            </Link>
            <Link onClick={() => menuHandler()} to={"/about"}>
              About us
            </Link>
            <Link onClick={() => menuHandler()} to={"/contact"}>
              Contact us
            </Link>
            <a
              href={require("../images/petrose/ProductCatalogueWeb.pdf")}
              download={"ProductCatalogueWeb2025"}
              className="BroBtn"
            >
              <FaDownload />
              Brochure
            </a>
          </div>

          {/* <div className="iconBoxx">
         
            <a href="https://www.instagram.com/invites/contact/?i=1eyki4m3i00vf&utm_content=q05ecy3" target="_blank">
              <FaInstagram />
            </a>
            <a href="https://youtube.com/@sauravkori217" target="_blank">
              <FaYoutube />
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href={"https://wa.me/72918 54677"}
            >
              <FaWhatsapp />
            </a>
            
          </div> */}
        </div>
      </header>
    </div>
  );
};

export default Header;
