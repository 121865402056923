import React from "react";
import { Link } from "react-router-dom";
import "./homePage.css";
const HomePage = () => {
  return (
    <div className="homePageMainContainer ">
      <div className="topSection">
        <div className="containBox">
          <h1>INNOVATING AROMAS</h1>
          {/* <p>Chemical compounds or single molecules that impart a scent or a flavor.</p> */}
          <Link className="button" to={"/about"}>
            Know More
          </Link>
        </div>
      </div>

      <div>
        <div className="heading">
          <h1>New Products</h1>
        </div>
        <div className="home-product-Box">
          <Link to={"product/hyacinth-core"} className="productItem">
            <div>
              <img
                src={require("../images/petrose/Hyacinth.png")}
                alt="Hyacinth Core"
              />
            </div>
            <p>Hyacinth Core</p>
          </Link>

          <Link to={"product/isocaproic-acid"} className="productItem">
            <div>
              <img
                src={require("../images/petrose/Isocaproic.png")}
                alt="Fadeus"
              />
            </div>
            <p>Isocaproic Acid </p>
          </Link>

          <Link to={"product/jalclone"} className="productItem">
            <div>
              <img
                src={require("../images/petrose/Jalclone.png")}
                alt="Jalclone"
              />
            </div>
            <p>Jalclone</p>
          </Link>
        </div>
      </div>

      {/* <div className='Section2'>
        <div className="s2Box">
          <h1>Introducing Anethole</h1>
          <p>Our Anethole production capacity is 40 MT/month.
            Being the chielf component of star anise, anise seed oil and sweet fennel, Anethole has a sweet and mildly spicy, floral and fruity, anisic-balsimic odour.</p>
          <Link to={'/about'} className='button'>Know More</Link>
        </div>
      </div> */}

      <div className="">
        <div className="s2Box1">
          {/* <h3> Upcoming Event </h3> */}
          <img
            src={require("../images/5march_banner_petorose.png")}
            alt="5 March Event"
          />
        </div>
      </div>

      <div>
        <div className="heading">
          <h1>Featured Products</h1>
        </div>
        <div className="home-product-Box">
          <Link to={"product/methyl-3-nonenoate"} className="productItem">
            <div>
              <img
                src={require("../images/petrose/Methyl-3-Nonanoate.jpg")}
                alt="Methyl-3-nonenoate"
              />
            </div>
            <p>Methyl-3-nonenoate</p>
          </Link>

          <Link to={"product/para-propyl-phenol"} className="productItem">
            <div>
              <img
                src={require("../images/petrose/Para Propyl Phenol.jpg")}
                alt="Para Propyl Phenol"
              />
            </div>

            <p>Para Propyl Phenol</p>
          </Link>

          <Link to={"product/chavibetol-50"} className="productItem">
            <div>
              <img
                src={require("../images/petrose/Chavibetol 50_.jpg")}
                alt="Chavibetol 50"
              />
            </div>
            <p>Chavibetol 50% </p>
          </Link>
        </div>
      </div>
      <br />

      <div className="center">
        <Link to={"/products"} className="button btnBg">
          All Products
        </Link>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default HomePage;
