import React, { useState } from "react";
import "./Modal.css";
import { IoCloseCircle } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import { Connection_Url } from "../utils/productionvariable";
import axios from "axios";

const Modal = ({ isOpen, onClose }) => {
  // if (!isOpen) return null;

  const [phone, setPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    // console.log(name, email, rating, dob)

    if (!phone) {
      alert("Required phone number");
      return;
    }
    const data = {
      phone,
    };

    setIsLoading(true);

    const url = `${Connection_Url}/api/v1/whatsapp`;
    await axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      })
      .then((res) => {
        alert("Email Send SuccussFully. Thank-you for Join us");
        setIsLoading(false);

        window.location.reload();
      })
      .catch((error) => {
        alert(`Technical Error. ${error}`);
        window.location.reload();
        setIsLoading(false);
      });
  };

  return (
    <div className="modal-overlay-whatsapp">
      <div className="modal-container">
        <div className="close-btn">
          <button onClick={onClose}>
            <IoCloseCircle />
          </button>
        </div>

        <h2 className="sendHeading">Request Brochure on WhatsApp</h2>

        <div className="modal-footer">
          <div className="responsive-input">
            <RiWhatsappFill />
            <input
              type="tel"
              id="whatsapp"
              placeholder="Please enter your WhatsApp No."
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          {isLoading ? (
            "wait..."
          ) : (
            <button className="send-btn" onClick={formSubmitHandler}>
              Submit
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
